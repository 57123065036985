// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

// Capitalize initial letter
export const capitalizeWord = s => `${s.charAt(0).toUpperCase()}${s.substr(1)}`;

export const toCapitalizedWords = word => {
  const words = word.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalizeWord).join(' ');
};
